import equal from 'fast-deep-equal';

import { SchemaObject } from '@app-types';

export default function isNewSchema(
  schema: SchemaObject | undefined,
  previousSchema: SchemaObject | undefined
): boolean {
  if (schema?._id === undefined) {
    return !equal(previousSchema, schema);
  }

  return schema?._id !== previousSchema?._id;
}
