import { FontFaces } from 'components-typescript-react';
import React, { useEffect } from 'react';

import { CommandTypes, ProductNames, WalletProps } from '@app-types';
import Tile from '@components/Tile';
import WorkflowStep from '@components/WorkflowStep';
import { INSTALMENT_BASE_URL } from '@components/WorkflowStep/productInfo';
import { AppPropManager } from '@utils';

import { PluginContainer, WalletOverlay, WalletWrapper } from './styles';

const fonts = FontFaces.map((fontFace) => ({
  ...fontFace,
  src: fontFace.src.map((source) => ({
    ...source,
    url: `${INSTALMENT_BASE_URL}${source.url?.replace(/^\//, '')}`,
  })),
}));

const Wallet: React.FC<WalletProps> = ({
  authToken,
  authCertificate,
  initToken,
  initCertificate,
  isOpen,
  isVisible,
  tilePosition,
  promotionalValue,
  onApplicationEvent,
  onEmit,
  error,
  firstAvailableProduct,
  onChangeActiveLender,
  isStandaloneUI,
}) => {
  const { isStandAlone } = initCertificate;
  const onSoftClose = (): void => onEmit({ type: CommandTypes.soft_close });
  const onClose = (): void => !isStandAlone && onEmit({ type: CommandTypes.close });
  const onOpen = (): void => onEmit({ type: CommandTypes.open });
  const clearPromotional = (): void => onEmit({ type: CommandTypes.clearPromotional });

  useEffect(() => {
    if (isStandAlone) {
      onOpen();
    }
  }, [isStandAlone]);

  return (
    <>
      {!isStandAlone && isVisible && (
        <WalletOverlay isOpen={isOpen} data-testid="wallet-overlay" onClick={onSoftClose} />
      )}
      {!isStandAlone &&
        initCertificate.availableProducts[0] !== ProductNames.MULTI_LENDER &&
        !AppPropManager.isCheckoutToken && (
          <Tile
            tilePosition={tilePosition}
            firstAvailableProduct={firstAvailableProduct}
            isVisible={isVisible}
            isOpen={isOpen}
            handleOpen={onOpen}
          />
        )}
      <PluginContainer isStandaloneUI={isStandaloneUI} isOpen={isOpen}>
        <WalletWrapper isVisible={isVisible} isOpen={isOpen} fonts={fonts}>
          <WorkflowStep
            authToken={authToken}
            authCertificate={authCertificate}
            initToken={initToken}
            initCertificate={initCertificate}
            promotional={promotionalValue}
            error={error}
            onApplicationEvent={onApplicationEvent}
            onClose={onClose}
            onChangeActiveLender={onChangeActiveLender}
            clearPromotional={clearPromotional}
          />
        </WalletWrapper>
      </PluginContainer>
    </>
  );
};

export default Wallet;
