import { StyleSheetManager, ThemeProvider } from 'styled-components';

import { Atoms, selectTheme } from 'components-typescript-react';
import React from 'react';

import { AppProps, LenderName } from '@app-types';
import Wallet from '@components/Wallet';
import { StyleReset } from '@components/Wallet/styles';
import { AppPropManager } from '@utils';
import { trackInteractionEvent } from '@utils/Event/trackEvent';
import { SourceComponent } from '@utils/Event/types';
import getLenderFromProduct from '@utils/getLenderFromProduct';
import themeSwitching from '@utils/themeSwitching';

import { AdapterVersion, ApiClientProvider, useApiClient } from '../ApiClient';

// clone of stylis-plugin-extra-scope
const ensureRootSelector = (rootSelector: string) => {
  const scope = rootSelector.trim();

  const plugin = (context, content, selectors, parents) => {
    if (context !== -1) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    selectors[0] = `${scope} ${parents.join(' ')}${selectors.join('')}`;
  };
  Object.defineProperty(plugin, 'name', { value: 'ensureRootSelectorPlugin' });

  return plugin;
};

const App: React.FC<AppProps> = ({
  initToken,
  authToken,
  authCertificate,
  initCertificate,
  rootSelector,
  onApplicationEvent,
  isVisible,
  tilePosition,
  promotionalValue,
  firstAvailableProduct,
  isOpen,
  onEmit,
  error,
  isStandaloneUI,
}: AppProps) => {
  const environmentVariables = React.useMemo(() => initCertificate?.env, [initCertificate]);
  const styledPlugins = React.useMemo(() => [ensureRootSelector(rootSelector)], [rootSelector]);
  const apiClient = useApiClient();
  const [activeLender, setActiveLender] = React.useState<LenderName>(() => getLenderFromProduct(firstAvailableProduct));
  const theme = React.useMemo(() => selectTheme(themeSwitching(activeLender)), [activeLender]);

  React.useEffect(() => {
    setActiveLender(getLenderFromProduct(firstAvailableProduct));
    trackInteractionEvent({
      sourceComponent: SourceComponent.DekoWidget,
      createdAt: new Date().toISOString(),
      stepAction: 'Widget Invoked',
      pageId: '',
      isStandalone: isStandaloneUI,
      token: authToken,
      url: initCertificate.eventApiUrl,
      apiClient: { captureEvent: apiClient.captureEvent, appId: apiClient.appId },
      isInsideCheckout: AppPropManager.isCheckoutToken,
    });
  }, [firstAvailableProduct]);

  const currentAdapter: AdapterVersion =
    process.env.API_ADAPTER in AdapterVersion ? AdapterVersion[process.env.API_ADAPTER] : AdapterVersion.live;

  return (
    <ApiClientProvider adapter={currentAdapter}>
      <ThemeProvider theme={theme}>
        <Atoms.ENVCONTEXT.Provider value={environmentVariables}>
          <StyleReset parentSelector={rootSelector} />
          <StyleSheetManager stylisPlugins={styledPlugins}>
            <Wallet
              key={authToken}
              initToken={initToken}
              initCertificate={initCertificate}
              authToken={authToken}
              authCertificate={authCertificate}
              rootSelector={rootSelector}
              onApplicationEvent={onApplicationEvent}
              promotionalValue={promotionalValue}
              isOpen={isOpen}
              isVisible={isVisible}
              tilePosition={tilePosition}
              onEmit={onEmit}
              error={error}
              firstAvailableProduct={firstAvailableProduct}
              onChangeActiveLender={setActiveLender}
              isStandaloneUI={isStandaloneUI}
            />
          </StyleSheetManager>
        </Atoms.ENVCONTEXT.Provider>
      </ThemeProvider>
    </ApiClientProvider>
  );
};

export default App;
